// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Xpbt3AO13clGBC4oGxDA{background-color:var(--color-neutrals-quartz);display:flex;flex-direction:column;height:100%;margin:0 auto;max-width:1440px;overflow:auto;padding:42px 56px;transition:.3s all ease}@media(max-width: 1024px){.Xpbt3AO13clGBC4oGxDA{padding-left:24px;padding-right:24px}}.e61nnudLEf63W8F7M07R{color:var(--color-neutrals-carbon);padding-left:10px}.yuwbuULiMbip6yepa5NG{align-items:center;display:flex;margin-bottom:35px;max-width:1440px;width:100%}@media(max-width: 1024px){.yuwbuULiMbip6yepa5NG{margin-bottom:30px}}.nZzAsTOoxmU2_6mSe3OC{display:flex;gap:24px;flex-wrap:wrap;margin-bottom:20px;max-width:1440px;width:100%}`, "",{"version":3,"sources":["webpack://./src/sections/document-cards/document-cards.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,6CAAA,CACA,YAAA,CACA,qBAAA,CACA,WAAA,CACA,aAAA,CACA,gBAAA,CACA,aAAA,CACA,iBAAA,CACA,uBAAA,CACA,0BAVF,sBAWI,iBAAA,CACA,kBAAA,CAAA,CAIJ,sBACE,kCAAA,CACA,iBAAA,CAGF,sBACE,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,UAAA,CACA,0BANF,sBAOI,kBAAA,CAAA,CAIJ,sBACE,YAAA,CACA,QAAA,CACA,cAAA,CACA,kBAAA,CACA,gBAAA,CACA,UAAA","sourcesContent":[".docsSectionContainer {\n  background-color: var(--color-neutrals-quartz);\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  margin: 0 auto;\n  max-width: 1440px;\n  overflow: auto;\n  padding: 42px 56px;\n  transition: 0.3s all ease;\n  @media (max-width: 1024px) {\n    padding-left: 24px;\n    padding-right: 24px;\n  }\n}\n\n.docsUploaded {\n  color: var(--color-neutrals-carbon);\n  padding-left: 10px;\n}\n\n.titleContainer {\n  align-items: center;\n  display: flex;\n  margin-bottom: 35px;\n  max-width: 1440px;\n  width: 100%;\n  @media (max-width: 1024px) {\n    margin-bottom: 30px;\n  }\n}\n\n.cardsContainer {\n  display: flex;\n  gap: 24px;\n  flex-wrap: wrap;\n  margin-bottom: 20px;\n  max-width: 1440px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"docsSectionContainer": `Xpbt3AO13clGBC4oGxDA`,
	"docsUploaded": `e61nnudLEf63W8F7M07R`,
	"titleContainer": `yuwbuULiMbip6yepa5NG`,
	"cardsContainer": `nZzAsTOoxmU2_6mSe3OC`
};
export default ___CSS_LOADER_EXPORT___;
