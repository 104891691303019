import { Fragment } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import NoDocumentsImage from "../../assets/images/no-documents.svg";
import { TypographyComponent } from "gx-npm-ui";
import styles from "./no-documents.styles.module.scss";
import InfoOutlineIcon from "./info-outline.icon";
import VectorIcon from "./vector.icon";
import { UserViews, UserViewTypes } from "../../app.types";

type NoDocumentsSectionProps = {
  userView?: UserViewTypes;
};

const NoDocumentsSection = ({ userView }: NoDocumentsSectionProps) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.noDocumentsContainer)}>
      <img alt="no documents" className={classNames(styles.noDocumentsImage)} src={NoDocumentsImage} />
      {userView === UserViews.RECIPIENT && (
        <TypographyComponent color={"iron"} styling="h4" boldness={"medium"}>
          {t("You have no document requests")}
        </TypographyComponent>
      )}
      {userView === UserViews.EVALUATOR && (
        <Fragment>
          <TypographyComponent color={"iron"} styling="h4" boldness={"medium"}>
            {t("No vendor documents provided.")}
          </TypographyComponent>
          <div className={classNames(styles.noDocumentsIconContainer)}>
            <VectorIcon />
            <div className={classNames(styles.noDocumentsCenteredIcon)}>
              <InfoOutlineIcon />
            </div>
            <VectorIcon />
          </div>
          <TypographyComponent color={"coal"} rootClassName={classNames(styles.noDocumentsSubtext)} styling="p4">
            {t("Vendor responses in BuySmart are not reviewed by Gartner Experts")}
            {` `}
            {t("and do not necessarily represent the views or opinions of Gartner.")}
          </TypographyComponent>
        </Fragment>
      )}
    </div>
  );
};

export default NoDocumentsSection;
