import classNames from "classnames";
import React, { useContext, useEffect } from "react";
import { loadSurveyDocumentData } from "./lib/requests.lib";
import DocumentCardsSection from "./sections/document-cards/document-cards.component";
import NoDocumentsSection from "./sections/no-documents/no-documents.component";
import TabLoaderSection from "./sections/tab-loader/tab-loader.component";
import { SurveyDocumentsAppContext } from "./app.context";
import styles from "./app.styles.module.scss";
import { SurveyPreviewData, SurveyDocument, SurveyPreviewItem, UserViews } from "./app.types";
import { useParams } from "react-router-dom";
import { GartnerFooter } from "gx-npm-ui";
import { UUID } from "gx-npm-lib";

const LOCAL_STORE_PREVIEW_KEY = "survey_setup";

const AppPreview = ({ isSetupMode }: { isSetupMode: boolean }) => {
  const {
    docsTotal,
    initId,
    initProdId,
    isLoading,
    userView,
    setDocsTotal,
    setDocsUploaded,
    setDocuments,
    setInitId,
    setIsLoading,
    setUserView,
    setSurveyId,
    setIsPreviewMode,
  } = useContext(SurveyDocumentsAppContext);
  const { initiativeId } = useParams();

  useEffect(() => {
    setInitId((initiativeId || "") as UUID);
    setUserView(UserViews.RECIPIENT);
    setIsPreviewMode(true);
  }, [initiativeId, setInitId, setIsPreviewMode, setUserView]);

  useEffect(() => {
    // Preview launched from inside setup

    if (!isSetupMode) {
      return;
    }
    const initialQuestionnaireDataForDocs = localStorage.getItem(LOCAL_STORE_PREVIEW_KEY);

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === LOCAL_STORE_PREVIEW_KEY) {
        const questionnaireData = localStorage.getItem(LOCAL_STORE_PREVIEW_KEY);
        if (questionnaireData) {
          updateContextWithLocalStore(questionnaireData);
        }
      }
    };
    const updateContextWithLocalStore = (storageData: string) => {
      const parsedData: SurveyPreviewData = JSON.parse(storageData);
      const storageDocuments = parsedData.requestedDocuments.map((document: SurveyPreviewItem) => {
        return {
          id: document.id,
          requestedDocName: document.name,
        };
      });
      setDocuments(storageDocuments as SurveyDocument[]);
      setDocsTotal(storageDocuments.length);
    };

    if (initialQuestionnaireDataForDocs) {
      updateContextWithLocalStore(initialQuestionnaireDataForDocs);
    }

    setIsLoading(false);
    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, [isSetupMode, setDocsTotal, setDocuments, setIsLoading]);

  useEffect(() => {
    // Preview launched from outside setup

    if (isSetupMode || !initId) {
      return;
    }
    loadSurveyDocumentData(
      { initId, initProdId, userView: UserViews.RECIPIENT, isPreviewMode: true },
      (data) => {
        const { documents, surveyId } = data;
        setDocsTotal(documents.length);
        setDocsUploaded(documents.filter((doc) => doc.uploadedDocName).length);
        setDocuments(documents);
        setSurveyId(surveyId);
      },
      (_error) => {},
      () => setIsLoading(false)
    ).then(() => {
      setIsLoading(false);
    });
  }, [
    initId,
    initProdId,
    isSetupMode,
    setDocsTotal,
    setDocsUploaded,
    setDocuments,
    setIsLoading,
    setSurveyId,
    userView,
  ]);

  return (
    <div className={classNames(styles.container)}>
      <section className={classNames(styles.root, styles.surveyDocumentViewForPreview)}>
        {isLoading && <TabLoaderSection />}
        {!isLoading && docsTotal === 0 && <NoDocumentsSection userView={userView} />}
        {!isLoading && docsTotal > 0 && <DocumentCardsSection />}
      </section>
      <GartnerFooter />
    </div>
  );
};

export default AppPreview;
