import classNames from "classnames";
import { Button, TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import Dialog from "../../../../ui/dialog/dialog.component";
import styles from "./document-cards-dialog.styles.module.scss";

const DialogSkipDocument = ({
  isOpen = false,
  onClose = (_skipDocument: boolean) => {
    /* do nothing */
  },
}: {
  isOpen: boolean;
  onClose: (_skipDocument: boolean) => void;
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      body={
        <TypographyComponent styling="p2">
          {t("Skipping will remove any document that has been uploaded. Are you sure you want to continue?")}
        </TypographyComponent>
      }
      open={isOpen}
      onClose={() => onClose(false)}
      title={t("Skip this document?")}
      footer={
        <div className={classNames(styles.btnContainer)}>
          <Button
            onClick={() => onClose(false)}
            rootClassName={classNames("gx-btn-cancel-skip-document", "btn-tertiary")}
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={() => onClose(true)}
            rootClassName={classNames("gx-btn-skip-document", "primary-destructive-btn")}
          >
            {t("Skip Document")}
          </Button>
        </div>
      }
    />
  );
};

export default DialogSkipDocument;
