import { deleteAsyncRequest, getAsyncRequest, postAsyncRequest, UUID } from "gx-npm-lib";
import { SurveyDocument, UserViewTypes } from "../app.types";

export const loadSurveyDocumentData = async (
  config: { initId: UUID; initProdId: UUID; userView: UserViewTypes; isPreviewMode: boolean },
  onSuccess: ({ documents, surveyId }: { documents: Array<SurveyDocument>; surveyId: UUID }) => void,
  onError: (error: object) => void,
  onComplete: () => void
) => {
  const { initId, initProdId, userView, isPreviewMode } = config;
  const url = isPreviewMode
    ? `api/v2/initiatives/${initId}/survey-preview/documents`
    : `/api/v2/initiatives/${initId}/survey-documents/${userView}/${initProdId}/documents`;
  const response = await getAsyncRequest(url);
  if (response.status === 200 && Array.isArray(response.data.data.documents)) {
    const { documents, surveyId } = response.data.data;
    onSuccess({ documents, surveyId });
  } else {
    onError(response);
  }
  onComplete();
};

export const skipSurveyDocument = async (
  config: { docId: UUID; initId: UUID; initProdId: UUID; surveyId: UUID },
  onSuccess: (docId: UUID) => void = (_docId) => {},
  onError: (error: object) => void = (_error) => {},
  onComplete: () => void = () => {}
) => {
  const { docId, initId, initProdId, surveyId } = config;
  const url = `/api/v2/initiatives/${initId}/survey-documents/recipient/${surveyId}/${initProdId}/documents/${docId}/skip`;
  const response = await postAsyncRequest(url);
  if (response.status === 201) {
    onSuccess(docId);
  } else {
    onError(response);
  }
  onComplete();
};

export const downloadSurveyDocument = async (
  config: { docId: UUID; initId: UUID; initProdId: UUID; surveyId: UUID; userView: UserViewTypes },
  onSuccess: (data: { signedUrl: string }) => void = (_data) => {},
  onError: (error: object) => void = (_error) => {},
  onComplete: () => void = () => {}
): Promise<void> => {
  const { docId, initId, initProdId, surveyId, userView } = config;
  const url = `/api/v2/initiatives/${initId}/survey-documents/${userView}/${surveyId}/${initProdId}/documents/${docId}/download`;
  const response = await getAsyncRequest(url);
  if (response.status === 200 && response.data?.data?.signedUrl) {
    const { signedUrl } = response.data.data;
    onSuccess({ signedUrl });
  } else {
    onError(response);
  }
  onComplete();
};

export const deleteSurveyDocument = async (
  config: { docId: UUID; initId: UUID; initProdId: UUID; surveyId: UUID },
  onSuccess: (docId: UUID) => void = (_docId) => {},
  onError: (error: object) => void = (_error) => {},
  onComplete: () => void = () => {}
) => {
  const { docId, initId, initProdId, surveyId } = config;
  const url = `/api/v2/initiatives/${initId}/survey-documents/recipient/${surveyId}/${initProdId}/documents/${docId}`;
  const response = await deleteAsyncRequest(url);
  if (response.status === 200) {
    onSuccess(docId);
  } else {
    onError(response);
  }
  onComplete();
};
