import { ChangeEvent, Fragment, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { SurveyDocumentsAppContext } from "../../../../app.context";
import Checkbox from "../../../../ui/checkbox/checkbox.component";
import DialogSkipDocument from "../dialogs/document-cards-dialog-skip.component";
import { UUID } from "gx-npm-lib";
import styles from "./document-card-checkbox-skip.styles.module.scss";
import { deleteSurveyDocument, skipSurveyDocument } from "../../../../lib/requests.lib";

const DocumentCheckboxSkip = ({ id, isSkipped = false }: { id: UUID; isSkipped: boolean }) => {
  const { t } = useTranslation();
  const {
    docsUploaded,
    isPreviewMode,
    documents,
    initId,
    initProdId,
    surveyId,
    setDocuments,
    setDocsUploaded,
    setShowErrorSnackBar,
  } = useContext(SurveyDocumentsAppContext);
  const [dialogSkipDocument, setDialogSkipDocument] = useState(false);

  const changeSkipState = (skipDocument: boolean) => {
    setDocuments(
      documents.map((document) => {
        if (document.id === id) {
          if (document.uploadedDocName) {
            setDocsUploaded(docsUploaded - 1);
          }
          return {
            ...document,
            isSkipped: skipDocument,
            uploadedDocBy: "",
            uploadedDocDate: "",
            uploadedDocName: "",
          };
        }
        return document;
      })
    );
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setDialogSkipDocument(true);
    } else if (isPreviewMode) {
      changeSkipState(false);
    } else {
      deleteSurveyDocument(
        { initId, initProdId, surveyId, docId: id },
        () => {
          changeSkipState(false);
        },
        () => {
          setShowErrorSnackBar(true);
        }
      );
    }
  };

  const handleDialogSkipDocument = (skipDocument: boolean) => {
    setDialogSkipDocument(false);
    if (skipDocument) {
      if (isPreviewMode) {
        changeSkipState(true);
      } else {
        skipSurveyDocument(
          { initId, initProdId, surveyId, docId: id },
          () => {
            changeSkipState(true);
          },
          () => {
            setShowErrorSnackBar(true);
          }
        );
      }
    }
  };

  return (
    <Fragment>
      <Checkbox
        checked={isSkipped}
        label={t("Skip this document")}
        onChange={handleCheckboxChange}
        rootClassName={styles.checkBoxSkipDocument}
        value={isSkipped}
      />
      <DialogSkipDocument isOpen={dialogSkipDocument} onClose={handleDialogSkipDocument} />
    </Fragment>
  );
};

export default DocumentCheckboxSkip;
