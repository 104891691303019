import { Fragment, KeyboardEvent, useContext, useRef, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { isValidDate, UUID } from "gx-npm-lib";
import { FileTypePdfIcon, FileTypeXlsxIcon } from "gx-npm-icons";
import { IconCheckMarkComplete } from "../../ui/icons/document.icons";
import DocumentButtonDelete from "./components/button-delete/document-card-button-delete.component";
import DocumentCheckboxSkip from "./components/checkbox-skip/document-card-checkbox-skip.component";
import { TypographyComponent } from "gx-npm-ui";
import { downloadSurveyDocument } from "../../lib/requests.lib";
import { useSyncElemHeight } from "../../lib/sync-height.lib";
import { SurveyDocumentsAppContext } from "../../app.context";
import { UserViews } from "../../app.types";
import styles from "./document-card.styles.module.scss";

type DocumentCardUploadedProps = {
  id: UUID;
  index?: number;
  isSkipped: boolean;
  requestedDocName: string;
  uploadedDocBy: string;
  uploadedDocDate: string;
  uploadedDocName: string;
};

const DocumentCardUploaded = ({
  id,
  index = 0,
  isSkipped,
  requestedDocName,
  uploadedDocBy,
  uploadedDocDate,
  uploadedDocName,
}: DocumentCardUploadedProps) => {
  const { t } = useTranslation();
  const [uploadedDocHover, setUploadedDocHover] = useState(false);
  const { initId, initProdId, setShowErrorSnackBar, surveyId, userView } = useContext(SurveyDocumentsAppContext);
  const cardRef = useRef(null);

  useSyncElemHeight({ ref: cardRef, index });

  const handleClickDownload = () => {
    downloadSurveyDocument(
      { docId: id, initId, initProdId, surveyId, userView },
      (data) => {
        window.open(data.signedUrl, "_self");
      },
      () => {
        setShowErrorSnackBar(true);
      }
    );
  };

  const handleKeyDownDownload = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleClickDownload();
    }
  };

  const renderUploadedDocTypeIcon = () => {
    const fileExtension = uploadedDocName.split(".").pop()?.toLowerCase();

    switch (fileExtension) {
      case "pdf":
        return (
          <span aria-label={t("pdf document")}>
            <FileTypePdfIcon />
          </span>
        );
      case "xls":
      case "xlsx":
        return (
          <span aria-label={t("excel document")}>
            <FileTypeXlsxIcon />
          </span>
        );
    }
  };

  const formatDate = (date: string) => {
    let formattedDate = "";
    if (isValidDate(date)) {
      formattedDate = new Date(date)
        .toLocaleString("en-us", {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
        })
        .replace(" at ", ", ");
    }
    return formattedDate;
  };

  return (
    <Fragment>
      <TypographyComponent boldness="medium" rootClassName={styles.requestedDocName} styling="p2">
        {requestedDocName}
      </TypographyComponent>
      <div
        aria-label={t("download document")}
        className={classNames(styles.docCardInner, styles.uploadedDoc)}
        onClick={handleClickDownload}
        onKeyDown={handleKeyDownDownload}
        onFocus={() => setUploadedDocHover(true)}
        onMouseOver={() => setUploadedDocHover(true)}
        onMouseLeave={() => setUploadedDocHover(false)}
        role="button"
        tabIndex={0}
      >
        {userView === UserViews.RECIPIENT && (
          <div className={classNames(styles.uploadedDocComplete)}>
            <IconCheckMarkComplete />
          </div>
        )}
        <div className={classNames(styles.uploadedDocWrapper)}>
          <div className={classNames(styles.uploadedDocType)}>{renderUploadedDocTypeIcon()}</div>
          <div>
            <div ref={cardRef}>
              <TypographyComponent boldness="medium" styling={"p3"} rootClassName={styles.uploadedDocName}>
                {uploadedDocName}
              </TypographyComponent>
            </div>
            <TypographyComponent rootClassName={classNames(styles.uploadedDocDate)} styling="p4">
              {formatDate(uploadedDocDate)}
            </TypographyComponent>
            <TypographyComponent rootClassName={classNames(styles.uploadedDocBy)} styling="p4">
              {t("by")}
              {` `}
              {uploadedDocBy}
            </TypographyComponent>
          </div>
          {uploadedDocHover && userView === UserViews.RECIPIENT && <DocumentButtonDelete id={id} />}
        </div>
      </div>
      {userView === UserViews.RECIPIENT && <DocumentCheckboxSkip id={id} isSkipped={isSkipped} />}
    </Fragment>
  );
};

export default DocumentCardUploaded;
