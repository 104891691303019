// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.C36KLdG2Hqy2iBFLiZw0.gx-remove-uploaded-doc{height:fit-content;margin-left:auto;margin-right:-12px;margin-top:-12px;order:2}`, "",{"version":3,"sources":["webpack://./src/sections/document-cards/components/button-delete/document-card-button-delete.styles.module.scss"],"names":[],"mappings":"AAAA,6CACE,kBAAA,CACA,gBAAA,CACA,kBAAA,CACA,gBAAA,CACA,OAAA","sourcesContent":[".removeDoc:global(.gx-remove-uploaded-doc) {\n  height: fit-content;\n  margin-left: auto;\n  margin-right: -12px;\n  margin-top: -12px;\n  order: 2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"removeDoc": `C36KLdG2Hqy2iBFLiZw0`
};
export default ___CSS_LOADER_EXPORT___;
