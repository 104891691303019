// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fA_NOWZOqYbM4vzU1VQ0{padding-top:24px}`, "",{"version":3,"sources":["webpack://./src/sections/document-cards/components/checkbox-skip/document-card-checkbox-skip.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA","sourcesContent":[".checkBoxSkipDocument {\n  padding-top: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkBoxSkipDocument": `fA_NOWZOqYbM4vzU1VQ0`
};
export default ___CSS_LOADER_EXPORT___;
