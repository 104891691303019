import { Fragment, useRef } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import DocumentCheckboxSkip from "./components/checkbox-skip/document-card-checkbox-skip.component";
import { TypographyComponent } from "gx-npm-ui";
import { UserViews, UserViewTypes } from "../../app.types";
import styles from "./document-card.styles.module.scss";
import { UUID } from "gx-npm-lib";
import { useSyncElemHeight } from "../../lib/sync-height.lib";

type DocumentCardSkipProps = {
  id: UUID;
  index?: number;
  isSkipped: boolean;
  requestedDocName: string;
  userView?: UserViewTypes;
};

const DocumentCardSkip = ({ id, index = 0, isSkipped, requestedDocName, userView }: DocumentCardSkipProps) => {
  const { t } = useTranslation();
  const cardRef = useRef(null);

  useSyncElemHeight({ ref: cardRef, index });

  return (
    <Fragment>
      <div ref={cardRef}>
        <TypographyComponent boldness="medium" rootClassName={styles.requestedDocName} styling="p2">
          {requestedDocName}
        </TypographyComponent>
      </div>
      <div className={classNames(styles.skippedDivider)} />
      <div className={classNames(styles.docCardInner, styles.skipped)}>
        <TypographyComponent boldness="medium" rootClassName={classNames(styles.skippedDocName)} styling="p4">
          {t("This document has been skipped.")}
        </TypographyComponent>
        {userView === UserViews.RECIPIENT && (
          <TypographyComponent rootClassName={classNames(styles.uploadedDocDesc)} styling="p4">
            {t("To edit your response and upload a document, uncheck the box below.")}
          </TypographyComponent>
        )}
      </div>
      {userView === UserViews.RECIPIENT && <DocumentCheckboxSkip id={id} isSkipped={isSkipped} />}
    </Fragment>
  );
};

export default DocumentCardSkip;
