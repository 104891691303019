// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BTDofF3njhGihuehN3pr{padding-right:81px}.BTDofF3njhGihuehN3pr .f3L0lZhYbxPrIYSf2sfi{padding-bottom:56px}.BTDofF3njhGihuehN3pr .IadKl4gk59ysuX3WlElS{display:flex;align-items:center;gap:15px;padding-bottom:12px}.BTDofF3njhGihuehN3pr .xzBxYLgizh6IRr0aTX2b{color:var(--color-neutrals-quartz);width:400px}.BTDofF3njhGihuehN3pr .u25x9EetVmUGjPBqQptr{width:400px;margin-bottom:56px}.BTDofF3njhGihuehN3pr .YYD5r3Sx7YZ8FjivcXai{background-color:var(--color-white);height:1px;opacity:.2;margin-bottom:12px}.BTDofF3njhGihuehN3pr .ocZy_ZbSU7NbFdqDq3hA{margin-bottom:16px}.BTDofF3njhGihuehN3pr .Q4hYBYT1zInVwJ0b9s15 .H8i7MOopYuXKtbemXQmx{width:320px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/researchSlideIn/components/market-guide-body.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,4CACE,mBAAA,CAEF,4CACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,mBAAA,CAGF,4CACE,kCAAA,CACA,WAAA,CAGF,4CACE,WAAA,CACA,kBAAA,CAEF,4CACE,mCAAA,CACA,UAAA,CACA,UAAA,CACA,kBAAA,CAEF,4CACE,kBAAA,CAGA,kEACE,WAAA","sourcesContent":[".contentBodyMidSectionLeft {\n  padding-right: 81px;\n  & .gx-gartner-mg-desc-text {\n    padding-bottom: 56px;\n  }\n  .contentBodyMidSectionTitleText {\n    display: flex;\n    align-items: center;\n    gap: 15px;\n    padding-bottom: 12px;\n  }\n\n  .contentBodyMidSectionDescText {\n    color: var(--color-neutrals-quartz);\n    width: 400px;\n  }\n\n  .contentBodyMidSectionDescTextGCOM3523 {\n    width: 400px;\n    margin-bottom: 56px;\n  }\n  .contentLearnMoreLine {\n    background-color: var(--color-white);\n    height: 1px;\n    opacity: 0.2;\n    margin-bottom: 12px;\n  }\n  .contentBodyMidSectionLearnMore {\n    margin-bottom: 16px;\n  }\n  .contentLink {\n    .contentBodyMidSectionLinkText {\n      width: 320px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentBodyMidSectionLeft": `BTDofF3njhGihuehN3pr`,
	"gx-gartner-mg-desc-text": `f3L0lZhYbxPrIYSf2sfi`,
	"contentBodyMidSectionTitleText": `IadKl4gk59ysuX3WlElS`,
	"contentBodyMidSectionDescText": `xzBxYLgizh6IRr0aTX2b`,
	"contentBodyMidSectionDescTextGCOM3523": `u25x9EetVmUGjPBqQptr`,
	"contentLearnMoreLine": `YYD5r3Sx7YZ8FjivcXai`,
	"contentBodyMidSectionLearnMore": `ocZy_ZbSU7NbFdqDq3hA`,
	"contentLink": `Q4hYBYT1zInVwJ0b9s15`,
	"contentBodyMidSectionLinkText": `H8i7MOopYuXKtbemXQmx`
};
export default ___CSS_LOADER_EXPORT___;
