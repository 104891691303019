import { MutableRefObject, useEffect, useLayoutEffect } from "react";

type Target = {
  ref: MutableRefObject<HTMLElement | null>;
  index: number;
};

const elementStore: Target[] = [];
const CARDS_PER_ROW = 3;

export const useSyncElemHeight = (targetEl: Target) => {
  useLayoutEffect(() => {
    const addToStore = (element: Target) => {
      elementStore.push(element);
      return () => {
        const index = elementStore.indexOf(element);

        if (index > -1) {
          elementStore.splice(index, 1);
        }
      };
    };

    const cleanups: (() => void)[] = [];

    cleanups.push(addToStore(targetEl));

    return () => {
      cleanups.forEach((cleanup) => cleanup());
    };
  }, [targetEl]);

  useEffect(() => {
    const handleResize = () => {
      if (elementStore) {
        const numRows = Math.ceil(elementStore.length / CARDS_PER_ROW);
        const maxHeights = new Array(numRows).fill(0);

        elementStore.forEach((element) => {
          if (element.ref.current) {
            element.ref.current.style.minHeight = "";
          }
        });

        if (window.innerWidth <= 1024) {
          return;
        }

        elementStore.forEach((element) => {
          const targetRow = Math.floor(element.index / CARDS_PER_ROW);

          if (element.ref.current && element.ref.current.clientHeight > maxHeights[targetRow]) {
            maxHeights[targetRow] = element.ref.current.clientHeight;
          }
        });

        elementStore.forEach((element) => {
          const targetRow = Math.floor(element.index / CARDS_PER_ROW);
          if (element.ref.current) {
            element.ref.current.style.minHeight = `${maxHeights[targetRow]}px`;
          }
        });
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
};
