// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OWg692wVt4eBxGy3xMcd{align-items:center;background-color:var(--color-neutrals-quartz);display:flex;flex-direction:column;height:100%;justify-content:center;padding:77px 20px 0 20px;text-align:center}.U7__m4nb13Un_Cz56D78{margin-bottom:27px}._0QFEX78OX3vcrZUH3kc{color:var(--color-neutrals-iron)}.NbrtBEHx9U_PrA_JaQwQ{color:var(--color-neutrals-coal);width:348px}.Ve4iN2sCwZdL98hupt2z{align-items:center;display:inline-flex;margin-bottom:8px;margin-top:32px}.W5ShNM2eJOHBdOXqzoRK{align-items:center;margin-left:8px;margin-right:8px}`, "",{"version":3,"sources":["webpack://./src/sections/no-documents/no-documents.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,6CAAA,CACA,YAAA,CACA,qBAAA,CACA,WAAA,CACA,sBAAA,CACA,wBAAA,CACA,iBAAA,CAGF,sBACE,kBAAA,CAGF,sBACE,gCAAA,CAGF,sBACE,gCAAA,CACA,WAAA,CAGF,sBACE,kBAAA,CACA,mBAAA,CACA,iBAAA,CACA,eAAA,CAGF,sBACE,kBAAA,CACA,eAAA,CACA,gBAAA","sourcesContent":[".noDocumentsContainer {\n  align-items: center;\n  background-color: var(--color-neutrals-quartz);\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: center;\n  padding: 77px 20px 0 20px;\n  text-align: center;\n}\n\n.noDocumentsImage {\n  margin-bottom: 27px;\n}\n\n.noDocumentsText {\n  color: var(--color-neutrals-iron);\n}\n\n.noDocumentsSubtext {\n  color: var(--color-neutrals-coal);\n  width: 348px;\n}\n\n.noDocumentsIconContainer {\n  align-items: center;\n  display: inline-flex;\n  margin-bottom: 8px;\n  margin-top: 32px;\n}\n\n.noDocumentsCenteredIcon {\n  align-items: center;\n  margin-left: 8px;\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noDocumentsContainer": `OWg692wVt4eBxGy3xMcd`,
	"noDocumentsImage": `U7__m4nb13Un_Cz56D78`,
	"noDocumentsText": `_0QFEX78OX3vcrZUH3kc`,
	"noDocumentsSubtext": `NbrtBEHx9U_PrA_JaQwQ`,
	"noDocumentsIconContainer": `Ve4iN2sCwZdL98hupt2z`,
	"noDocumentsCenteredIcon": `W5ShNM2eJOHBdOXqzoRK`
};
export default ___CSS_LOADER_EXPORT___;
