import { Dispatch, SetStateAction } from "react";
import { UUID } from "gx-npm-lib";

export enum FileUploadErrors {
  INVALID_FILE = "INVALID_FILE",
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
  UPLOAD_POST_ERROR = "UPLOAD_POST_ERROR",
  UPLOAD_PUT_ERROR = "UPLOAD_PUT_ERROR",
}

export enum UserViews {
  EVALUATOR = "evaluator",
  RECIPIENT = "recipient",
}

export type UserViewTypes = "" | UserViews;

export type SurveyDocumentsAppContextValue = {
  isPreviewMode: boolean;
  docsTotal: number;
  docsUploaded: number;
  documents: Array<SurveyDocument>;
  isLoading: boolean;
  initId: UUID;
  initProdId: UUID;
  showErrorSnackBar: boolean;
  showSuccessSnackBar: boolean;
  surveyId: UUID;
  userView: UserViewTypes;
  setIsPreviewMode: Dispatch<SetStateAction<boolean>>;
  setDocsTotal: Dispatch<SetStateAction<number>>;
  setDocsUploaded: Dispatch<SetStateAction<number>>;
  setDocuments: Dispatch<SetStateAction<Array<SurveyDocument>>>;
  setInitId: Dispatch<SetStateAction<UUID>>;
  setInitProdId: Dispatch<SetStateAction<UUID>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setShowErrorSnackBar: Dispatch<SetStateAction<boolean>>;
  setShowSuccessSnackBar: Dispatch<SetStateAction<boolean>>;
  setSurveyId: Dispatch<SetStateAction<UUID>>;
  setUserView: Dispatch<SetStateAction<UserViewTypes>>;
};

export type SurveyDocument = {
  id: UUID;
  isSkipped: boolean;
  requestedDocName: string;
  uploadedDocBy: string;
  uploadedDocDate: string;
  uploadedDocName: string;
};

export type SurveyPreviewItem = {
  id: UUID;
  index: number;
  isDeleted?: boolean;
  name: string;
};

export type RequirementCategoryDto = {
  id: UUID;
  name: string;
  items: RequirementItemType[];
  comment: string;
};

export type RequirementItemType = {
  id: UUID;
  name: string;
  comment: string;
  responseOptionId: UUID;
  description: string;
};

export type SurveyPreviewData = {
  companyName: string;
  instructions: string;
  requestedDocuments: SurveyPreviewItem[];
  responseOptions: SurveyPreviewItem[];
  selectedCategories: RequirementCategoryDto[];
  isValid: boolean;
  requirementsCount: number;
  initiativeId: string;
};
