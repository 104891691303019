import { messengerFlagLoader } from "gx-npm-lib";
import { FeatureFlagBooleanContainer, FeatureFlagBooleanOff, FeatureFlagProvider } from "gx-npm-ui";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GCOM_3787__fileHub } from "./lib/feature-flag";
import AppPreview from "./app-preview.component";
import App from "./app.component";
import { SurveyDocumentsAppContextProvider } from "./app.context";

const Root = () => {
  return (
    <FeatureFlagProvider loader={messengerFlagLoader()}>
      <SurveyDocumentsAppContextProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path={"/s/evaluation/:initiativeId/questionnaire/preview/documents"}
              element={<AppPreview isSetupMode={false} />}
            />
            <Route
              path={"/s/evaluation/:initiativeId/questionnaire/setup/preview/documents"}
              element={<AppPreview isSetupMode={true} />}
            />
            <Route
              path={"/s/evaluation/:initiativeId/questionnaire/setup/:surveyId/preview/documents"}
              element={<AppPreview isSetupMode={true} />}
            />
            <Route
              path={"/s/evaluation/:initiativeId/product/:initProductId/documents"}
              element={
                <FeatureFlagBooleanContainer strictMode={true} flagName={GCOM_3787__fileHub}>
                  <FeatureFlagBooleanOff>
                    <App />
                  </FeatureFlagBooleanOff>
                </FeatureFlagBooleanContainer>
              }
            />
            <Route path={"/s/questionnaire/:initiativeId/product/:initProductId/documents"} element={<App />} />
          </Routes>
        </BrowserRouter>
      </SurveyDocumentsAppContextProvider>
    </FeatureFlagProvider>
  );
};

export default Root;
