// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.b0DMqA2qteez4kVYon8w{display:flex}.b0DMqA2qteez4kVYon8w button{margin-left:24px;margin-right:0}.b0DMqA2qteez4kVYon8w button:nth-child(1){margin-left:auto}`, "",{"version":3,"sources":["webpack://./src/sections/document-cards/components/dialogs/document-cards-dialog.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAGF,6BACE,gBAAA,CACA,cAAA,CAGF,0CACE,gBAAA","sourcesContent":[".btnContainer {\n  display: flex;\n}\n\n.btnContainer button {\n  margin-left: 24px;\n  margin-right: 0;\n}\n\n.btnContainer button:nth-child(1) {\n  margin-left: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnContainer": `b0DMqA2qteez4kVYon8w`
};
export default ___CSS_LOADER_EXPORT___;
