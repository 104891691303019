const InfoOutlineIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6151_19073)">
        <path
          d="M7.33333 4.66634H8.66666V5.99967H7.33333V4.66634ZM8 11.333C8.36666 11.333 8.66666 11.033 8.66666 10.6663V7.99967C8.66666 7.63301 8.36666 7.33301 8 7.33301C7.63333 7.33301 7.33333 7.63301 7.33333 7.99967V10.6663C7.33333 11.033 7.63333 11.333 8 11.333ZM8 1.33301C4.31999 1.33301 1.33333 4.31967 1.33333 7.99967C1.33333 11.6797 4.31999 14.6663 8 14.6663C11.68 14.6663 14.6667 11.6797 14.6667 7.99967C14.6667 4.31967 11.68 1.33301 8 1.33301ZM8 13.333C5.06 13.333 2.66666 10.9397 2.66666 7.99967C2.66666 5.05967 5.06 2.66634 8 2.66634C10.94 2.66634 13.3333 5.05967 13.3333 7.99967C13.3333 10.9397 10.94 13.333 8 13.333Z"
          fill="#676565"
        />
      </g>
      <defs>
        <clipPath id="clip0_6151_19073">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InfoOutlineIcon;
