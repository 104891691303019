import { colorPalette } from "gx-npm-common-styles";

const IconCheckMarkComplete = ({ title = "" }) => {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      {title && <title>{title}</title>}
      <path
        d="M7.99992 1.33301C4.31992 1.33301 1.33325 4.31967 1.33325 7.99967C1.33325 11.6797 4.31992 14.6663 7.99992 14.6663C11.6799 14.6663 14.6666 11.6797 14.6666 7.99967C14.6666 4.31967 11.6799 1.33301 7.99992 1.33301ZM6.19325 10.8597L3.79992 8.46634C3.53992 8.20634 3.53992 7.78634 3.79992 7.52634C4.05992 7.26634 4.47992 7.26634 4.73992 7.52634L6.66659 9.44634L11.2533 4.85967C11.5133 4.59967 11.9333 4.59967 12.1933 4.85967C12.4533 5.11967 12.4533 5.53967 12.1933 5.79967L7.13325 10.8597C6.87992 11.1197 6.45325 11.1197 6.19325 10.8597Z"
        fill={colorPalette.status.darkPear.hex}
      />
    </svg>
  );
};

export { IconCheckMarkComplete };
