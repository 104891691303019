import { createContext, ReactNode, useState } from "react";
import { SurveyDocument, SurveyDocumentsAppContextValue, UserViewTypes } from "./app.types";
import { UUID } from "gx-npm-lib";

const SurveyDocumentsAppContext = createContext<SurveyDocumentsAppContextValue>({} as SurveyDocumentsAppContextValue);
const SurveyDocumentsAppContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [docsTotal, setDocsTotal] = useState<number>(0);
  const [docsUploaded, setDocsUploaded] = useState<number>(0);
  const [documents, setDocuments] = useState<Array<SurveyDocument>>([]);
  const [initId, setInitId] = useState<UUID>("");
  const [initProdId, setInitProdId] = useState<UUID>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showErrorSnackBar, setShowErrorSnackBar] = useState<boolean>(false);
  const [showSuccessSnackBar, setShowSuccessSnackBar] = useState<boolean>(false);
  const [surveyId, setSurveyId] = useState<UUID>("");
  const [userView, setUserView] = useState<UserViewTypes>("");
  const [isPreviewMode, setIsPreviewMode] = useState(false);

  const contextValue = {
    docsTotal,
    docsUploaded,
    documents,
    initId,
    initProdId,
    isLoading,
    showErrorSnackBar,
    showSuccessSnackBar,
    surveyId,
    userView,
    isPreviewMode,
    setDocsTotal,
    setDocsUploaded,
    setDocuments,
    setInitId,
    setInitProdId,
    setIsLoading,
    setShowErrorSnackBar,
    setShowSuccessSnackBar,
    setSurveyId,
    setUserView,
    setIsPreviewMode,
  };
  return <SurveyDocumentsAppContext.Provider value={contextValue}>{props.children}</SurveyDocumentsAppContext.Provider>;
};

export { SurveyDocumentsAppContext, SurveyDocumentsAppContextProvider };
