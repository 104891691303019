import classNames from "classnames";
import React, { useContext, useEffect } from "react";
import { loadSurveyDocumentData } from "./lib/requests.lib";
import DocumentCardsSection from "./sections/document-cards/document-cards.component";
import NoDocumentsSection from "./sections/no-documents/no-documents.component";
import TabLoaderSection from "./sections/tab-loader/tab-loader.component";
import { SurveyDocumentsAppContext } from "./app.context";
import styles from "./app.styles.module.scss";
import { UserViews } from "./app.types";
import { useParams } from "react-router-dom";
import { GartnerFooter } from "gx-npm-ui";
import { UUID } from "gx-npm-lib";

const App = () => {
  const {
    docsTotal,
    initId,
    initProdId,
    isLoading,
    userView,
    setDocsTotal,
    setDocsUploaded,
    setDocuments,
    setInitId,
    setInitProdId,
    setIsLoading,
    setUserView,
    setSurveyId,
  } = useContext(SurveyDocumentsAppContext);

  const { initiativeId, initProductId } = useParams();

  useEffect(() => {
    setInitId((initiativeId || "") as UUID);
    setInitProdId((initProductId || "") as UUID);
    setUserView(window.location.pathname.includes("questionnaire") ? UserViews.RECIPIENT : UserViews.EVALUATOR);
  }, [initProductId, initiativeId, setInitId, setInitProdId, setUserView]);

  useEffect(() => {
    if (!initId || !initProdId || !userView) {
      return;
    }
    loadSurveyDocumentData(
      { initId, initProdId, userView, isPreviewMode: false },
      (data) => {
        const { documents, surveyId } = data;
        setDocsTotal(documents.length);
        setDocsUploaded(documents.filter((doc) => doc.uploadedDocName).length);
        setDocuments(documents);
        setSurveyId(surveyId);
      },
      (_error) => {},
      () => setIsLoading(false)
    );
  }, [initId, initProdId, setDocsTotal, setDocsUploaded, setDocuments, setIsLoading, setSurveyId, userView]);

  return (
    <div className={classNames(styles.container)}>
      <section
        className={classNames(
          styles.root,
          userView === UserViews.RECIPIENT ? styles.surveyDocumentView : styles.evalDocumentView
        )}
      >
        {isLoading && <TabLoaderSection />}
        {!isLoading && docsTotal === 0 && <NoDocumentsSection userView={userView} />}
        {!isLoading && docsTotal > 0 && <DocumentCardsSection />}
      </section>
      <GartnerFooter />
    </div>
  );
};

export default App;
