import classNames from "classnames";
import { DeleteTrashcanIcon } from "gx-npm-icons";
import { UUID } from "gx-npm-lib";
import { TooltipV2 } from "gx-npm-ui";
import { Fragment, MouseEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { SurveyDocumentsAppContext } from "../../../../app.context";
import { deleteSurveyDocument } from "../../../../lib/requests.lib";
import { IconButton } from "../../../../ui/icon-button/icon-button.component";
import DialogDeleteDocument from "../dialogs/document-cards-dialog-delete.component";
import styles from "./document-card-button-delete.styles.module.scss";

const DocumentDeleteButton = ({ id = "" }: { id: UUID }) => {
  const { t } = useTranslation();
  const { docsUploaded, initId, initProdId, setDocuments, setDocsUploaded, surveyId } =
    useContext(SurveyDocumentsAppContext);
  const [dialogDeleteDocument, setDialogDeleteDocument] = useState(false);

  const handleClose = (isDeleted: boolean) => {
    setDialogDeleteDocument(false);
    if (!isDeleted) {
      return;
    }
    deleteSurveyDocument({ docId: id, initId, initProdId, surveyId }, handleSuccessDelete);
  };

  const handleSuccessDelete = (docId: UUID) => {
    setDocsUploaded(docsUploaded - 1);
    setDocuments((prev) =>
      prev.map((doc) => {
        return doc.id !== docId
          ? doc
          : { ...doc, isSkipped: false, uploadedDocBy: "", uploadedDocDate: "", uploadedDocName: "" };
      })
    );
  };

  const handleClickDelete = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setDialogDeleteDocument(true);
  };

  return (
    <Fragment>
      <TooltipV2 placement="top" title={t("Delete document")}>
        <div className={classNames("gx-remove-uploaded-doc", styles.removeDoc)}>
          <IconButton
            aria-label={t("remove uploaded document")}
            hoverBackgroundColor="transparent"
            hoverFill="poison-cherry"
            onClick={handleClickDelete}
          >
            <DeleteTrashcanIcon />
          </IconButton>
        </div>
      </TooltipV2>
      <DialogDeleteDocument onClose={handleClose} isOpen={dialogDeleteDocument} />
    </Fragment>
  );
};

export default DocumentDeleteButton;
