// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ABHBEiFTQiubZ5viTE1K{--survey-workspace-header-max-height: 212px;--eval-workspace-header-max-height: 354px;--footer-height: 128px;display:flex;flex-direction:column}.ABHBEiFTQiubZ5viTE1K .Y_qJllfAYX3HZlrhxwMN{background-color:var(--color-neutrals-quartz);flex-grow:1;overflow-y:auto}.ABHBEiFTQiubZ5viTE1K .WqwvSb6ZbblGDcJOUSIL{min-height:calc(100vh - var(--survey-workspace-header-max-height) - var(--footer-height))}.ABHBEiFTQiubZ5viTE1K .ZDDFuQbFW4NPOtzk2A8s{min-height:calc(100vh - var(--eval-workspace-header-max-height) - var(--footer-height))}.ABHBEiFTQiubZ5viTE1K .Ux6UL_ShCEbwS1rufV9X{min-height:calc(100vh - var(--survey-workspace-header-max-height) - var(--footer-height) - 48px)}`, "",{"version":3,"sources":["webpack://./src/app.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,2CAAA,CACA,yCAAA,CACA,sBAAA,CACA,YAAA,CACA,qBAAA,CAGF,4CACE,6CAAA,CACA,WAAA,CACA,eAAA,CAGF,4CACE,yFAAA,CAGF,4CACE,uFAAA,CAGF,4CACE,gGAAA","sourcesContent":[".container {\n  --survey-workspace-header-max-height: 212px;\n  --eval-workspace-header-max-height: 354px;\n  --footer-height: 128px;\n  display: flex;\n  flex-direction: column;\n}\n\n.container .root {\n  background-color: var(--color-neutrals-quartz);\n  flex-grow: 1;\n  overflow-y: auto;\n}\n\n.container .surveyDocumentView {\n  min-height: calc(100vh - var(--survey-workspace-header-max-height) - var(--footer-height));\n}\n\n.container .evalDocumentView {\n  min-height: calc(100vh - var(--eval-workspace-header-max-height) - var(--footer-height));\n}\n\n.container .surveyDocumentViewForPreview {\n  min-height: calc(100vh - var(--survey-workspace-header-max-height) - var(--footer-height) - 48px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ABHBEiFTQiubZ5viTE1K`,
	"root": `Y_qJllfAYX3HZlrhxwMN`,
	"surveyDocumentView": `WqwvSb6ZbblGDcJOUSIL`,
	"evalDocumentView": `ZDDFuQbFW4NPOtzk2A8s`,
	"surveyDocumentViewForPreview": `Ux6UL_ShCEbwS1rufV9X`
};
export default ___CSS_LOADER_EXPORT___;
